import React, { FC } from "react";

interface Props {
  alt: string;
  src: string;
  height?: number;
  width?: number;
}

export const Image: FC<Props> = ({ alt,  src, height, width }) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  return (
    <>
      <img
        onLoad={() => {
          setIsLoaded(true);
        }}
        className="lazy-image full"
        style={{ opacity: isLoaded ? 1 : 0, width: width, height: height }}
        alt={alt}
        src={src}
      />
    </>
  );
};
