import React, { FC } from "react";
import { AboutShapes } from "../assets/svg";
import ArticleCard from "../components/ArticleCard";
import ArticleWrapper from "../components/ArticleWrapper";
import { Content } from "../components/Content";
import { images } from "../util/image";

interface Props {
  isMobile: boolean;
  articleRef: React.MutableRefObject<null>;
}

export const Articles: FC<Props> = ({ isMobile, articleRef }) => {
  return (
    <section id="articles" ref={articleRef}>
      <Content>
        {isMobile ? <></> : <AboutShapes />}
        <h1>Articles</h1>
        <ArticleWrapper>
          <ArticleCard
            description={
              "Meet the 5 tech companies I'll be watching in 2021 in the cannabis industry."
            }
            title={"Cannabis Tech Companies To Watch In 2021"}
            image={images.articles.fullstack}
            tags={["Cannabis", "Technology"]}
            link={
              "https://www.linkedin.com/pulse/cannabis-tech-companies-watch-2021-garrett-hampton/"
            }
          />
          <ArticleCard
            description={
              "Read about my 5 predicitions for the cannabis industry in 2021."
            }
            title={"My Predictions For Cannabis 2021"}
            image={images.articles.networking}
            tags={["Cannabis", "Business"]}
            link={"https://www.linkedin.com/pulse/my-predictions-cannabis-2021-garrett-hampton/"}
          />
          <ArticleCard
            description={
              "Learn how to add free SSL from LetsEncrypt to your Bitnami Grafana on AWS.."
            }
            title={"Adding SSL to Grafana by Bitnami on AWS with LetsEncrypt"}
            image={images.articles.appearance}
            tags={["Security", "Cloud Hosting"]}
            link={"https://garrettsyhampton.wordpress.com/2019/10/03/adding-ssl-to-grafana-by-bitnami-on-aws-with-letsencrypt/"}
          />
        </ArticleWrapper>
      </Content>
    </section>
  );
};
