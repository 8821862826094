import React, { FC } from "react";
import { animated } from "react-spring";
import JRKHero from "../images/jrkhero.png";
import Img from "react-cool-img";
import HeroPlaceholder from "../images/hero-placeholder.svg";
import "./aboutshapes.scss";
import "./heroshapes.scss";

export const AboutShapes = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 787.96 435.61"
      className="shapes"
    >
      <animated.g id="Group_107" data-name="Group 107" className="about-cls-1">
        <circle id="Ellipse_26" data-name="Ellipse 26" className="about-cls-2" cx="217.8" cy="8.5" r="8.5" />
        <circle id="Ellipse_26-2" data-name="Ellipse 26-2" className="about-cls-2" cx="243.97" cy="34.66" r="8.5" />
        <circle id="Ellipse_26-3" data-name="Ellipse 26-3" className="about-cls-2" cx="270.13" cy="60.83" r="8.5" />
        <circle id="Ellipse_26-4" data-name="Ellipse 26-4" className="about-cls-2" cx="322.46" cy="113.15" r="8.5" />
        <circle id="Ellipse_26-5" data-name="Ellipse 26-5" className="about-cls-2" cx="348.62" cy="139.31" r="8.5" />
        <circle id="Ellipse_26-6" data-name="Ellipse 26-6" className="about-cls-2" cx="374.78" cy="165.48" r="8.5" />
        <circle id="Ellipse_26-7" data-name="Ellipse 26-7" className="about-cls-2" cx="400.94" cy="191.64" r="8.5" />
        <circle id="Ellipse_26-8" data-name="Ellipse 26-8" className="about-cls-2" cx="427.11" cy="217.8" r="8.5" />
        <circle id="Ellipse_26-9" data-name="Ellipse 26-9" className="about-cls-2" cx="191.64" cy="34.66" r="8.5" />
        <circle id="Ellipse_26-10" data-name="Ellipse 26-10" className="about-cls-2" cx="243.97" cy="86.99" r="8.5" />
        <circle id="Ellipse_26-11" data-name="Ellipse 26-11" className="about-cls-2" cx="270.13" cy="113.15" r="8.5" />
        <circle id="Ellipse_26-12" data-name="Ellipse 26-12" className="about-cls-2" cx="296.29" cy="139.31" r="8.5" />
        <circle id="Ellipse_26-13" data-name="Ellipse 26-13" className="about-cls-2" cx="348.62" cy="191.64" r="8.5" />
        <circle id="Ellipse_26-14" data-name="Ellipse 26-14" className="about-cls-2" cx="374.78" cy="217.8" r="8.5" />
        <circle id="Ellipse_26-15" data-name="Ellipse 26-15" className="about-cls-2" cx="400.94" cy="243.97" r="8.5" />
        <circle id="Ellipse_26-16" data-name="Ellipse 26-16" className="about-cls-2" cx="165.48" cy="60.83" r="8.5" />
        <circle id="Ellipse_26-17" data-name="Ellipse 26-17" className="about-cls-2" cx="191.64" cy="86.99" r="8.5" />
        <circle id="Ellipse_26-18" data-name="Ellipse 26-18" className="about-cls-2" cx="217.8" cy="113.15" r="8.5" />
        <circle id="Ellipse_26-19" data-name="Ellipse 26-19" className="about-cls-2" cx="243.97" cy="139.31" r="8.5" />
        <circle id="Ellipse_26-20" data-name="Ellipse 26-20" className="about-cls-2" cx="270.13" cy="165.48" r="8.5" />
        <circle id="Ellipse_26-21" data-name="Ellipse 26-21" className="about-cls-2" cx="296.29" cy="191.64" r="8.5" />
        <circle id="Ellipse_26-22" data-name="Ellipse 26-22" className="about-cls-2" cx="322.46" cy="217.8" r="8.5" />
        <circle id="Ellipse_26-23" data-name="Ellipse 26-23" className="about-cls-2" cx="348.62" cy="243.97" r="8.5" />
        <circle id="Ellipse_26-24" data-name="Ellipse 26-24" className="about-cls-2" cx="139.31" cy="86.99" r="8.5" />
        <circle id="Ellipse_26-25" data-name="Ellipse 26-25" className="about-cls-2" cx="165.48" cy="113.15" r="8.5" />
        <circle id="Ellipse_26-26" data-name="Ellipse 26-26" className="about-cls-2" cx="191.64" cy="139.31" r="8.5" />
        <circle id="Ellipse_26-27" data-name="Ellipse 26-27" className="about-cls-2" cx="217.8" cy="165.48" r="8.5" />
        <circle id="Ellipse_26-28" data-name="Ellipse 26-28" className="about-cls-2" cx="243.97" cy="191.64" r="8.5" />
        <circle id="Ellipse_26-29" data-name="Ellipse 26-29" className="about-cls-2" cx="270.13" cy="217.8" r="8.5" />
        <circle id="Ellipse_26-30" data-name="Ellipse 26-30" className="about-cls-2" cx="296.29" cy="243.97" r="8.5" />
        <circle id="Ellipse_26-31" data-name="Ellipse 26-31" className="about-cls-2" cx="322.46" cy="270.13" r="8.5" />
        <circle id="Ellipse_26-32" data-name="Ellipse 26-32" className="about-cls-2" cx="348.62" cy="296.29" r="8.5" />
        <circle id="Ellipse_26-33" data-name="Ellipse 26-33" className="about-cls-2" cx="113.15" cy="113.15" r="8.5" />
        <circle id="Ellipse_26-34" data-name="Ellipse 26-34" className="about-cls-2" cx="139.31" cy="139.31" r="8.5" />
        <circle id="Ellipse_26-35" data-name="Ellipse 26-35" className="about-cls-2" cx="191.64" cy="191.64" r="8.5" />
        <circle id="Ellipse_26-36" data-name="Ellipse 26-36" className="about-cls-2" cx="217.8" cy="217.8" r="8.5" />
        <circle id="Ellipse_26-37" data-name="Ellipse 26-37" className="about-cls-2" cx="243.97" cy="243.97" r="8.5" />
        <circle id="Ellipse_26-38" data-name="Ellipse 26-38" className="about-cls-2" cx="296.29" cy="296.29" r="8.5" />
        <circle id="Ellipse_26-39" data-name="Ellipse 26-39" className="about-cls-2" cx="322.46" cy="322.46" r="8.5" />
        <circle id="Ellipse_26-40" data-name="Ellipse 26-40" className="about-cls-2" cx="86.99" cy="139.31" r="8.5" />
        <circle id="Ellipse_26-41" data-name="Ellipse 26-41" className="about-cls-2" cx="113.15" cy="165.48" r="8.5" />
        <circle id="Ellipse_26-42" data-name="Ellipse 26-42" className="about-cls-2" cx="139.31" cy="191.64" r="8.5" />
        <circle id="Ellipse_26-43" data-name="Ellipse 26-43" className="about-cls-2" cx="165.48" cy="217.8" r="8.5" />
        <circle id="Ellipse_26-44" data-name="Ellipse 26-44" className="about-cls-2" cx="191.64" cy="243.97" r="8.5" />
        <circle id="Ellipse_26-45" data-name="Ellipse 26-45" className="about-cls-2" cx="217.8" cy="270.13" r="8.5" />
        <circle id="Ellipse_26-46" data-name="Ellipse 26-46" className="about-cls-2" cx="243.97" cy="296.29" r="8.5" />
        <circle id="Ellipse_26-47" data-name="Ellipse 26-47" className="about-cls-2" cx="270.13" cy="322.46" r="8.5" />
        <circle id="Ellipse_26-48" data-name="Ellipse 26-48" className="about-cls-2" cx="296.29" cy="348.62" r="8.5" />
        <circle id="Ellipse_26-49" data-name="Ellipse 26-49" className="about-cls-2" cx="86.99" cy="191.64" r="8.5" />
        <circle id="Ellipse_26-50" data-name="Ellipse 26-50" className="about-cls-2" cx="113.15" cy="217.8" r="8.5" />
        <circle id="Ellipse_26-51" data-name="Ellipse 26-51" className="about-cls-2" cx="139.31" cy="243.97" r="8.5" />
        <circle id="Ellipse_26-52" data-name="Ellipse 26-52" className="about-cls-2" cx="165.48" cy="270.13" r="8.5" />
        <circle id="Ellipse_26-53" data-name="Ellipse 26-53" className="about-cls-2" cx="191.64" cy="296.29" r="8.5" />
        <circle id="Ellipse_26-54" data-name="Ellipse 26-54" className="about-cls-2" cx="217.8" cy="322.46" r="8.5" />
        <circle id="Ellipse_26-55" data-name="Ellipse 26-55" className="about-cls-2" cx="243.97" cy="348.62" r="8.5" />
        <circle id="Ellipse_26-56" data-name="Ellipse 26-56" className="about-cls-2" cx="270.13" cy="374.78" r="8.5" />
        <circle id="Ellipse_26-57" data-name="Ellipse 26-57" className="about-cls-2" cx="34.66" cy="191.64" r="8.5" />
        <circle id="Ellipse_26-58" data-name="Ellipse 26-58" className="about-cls-2" cx="60.83" cy="217.8" r="8.5" />
        <circle id="Ellipse_26-59" data-name="Ellipse 26-59" className="about-cls-2" cx="86.99" cy="243.97" r="8.5" />
        <circle id="Ellipse_26-60" data-name="Ellipse 26-60" className="about-cls-2" cx="113.15" cy="270.13" r="8.5" />
        <circle id="Ellipse_26-61" data-name="Ellipse 26-61" className="about-cls-2" cx="139.31" cy="296.29" r="8.5" />
        <circle id="Ellipse_26-62" data-name="Ellipse 26-62" className="about-cls-2" cx="165.48" cy="322.46" r="8.5" />
        <circle id="Ellipse_26-63" data-name="Ellipse 26-63" className="about-cls-2" cx="191.64" cy="348.62" r="8.5" />
        <circle id="Ellipse_26-64" data-name="Ellipse 26-64" className="about-cls-2" cx="243.97" cy="400.94" r="8.5" />
        <circle id="Ellipse_26-65" data-name="Ellipse 26-65" className="about-cls-2" cx="8.5" cy="217.8" r="8.5" />
        <circle id="Ellipse_26-66" data-name="Ellipse 26-66" className="about-cls-2" cx="34.66" cy="243.97" r="8.5" />
        <circle id="Ellipse_26-67" data-name="Ellipse 26-67" className="about-cls-2" cx="60.83" cy="270.13" r="8.5" />
        <circle id="Ellipse_26-68" data-name="Ellipse 26-68" className="about-cls-2" cx="113.15" cy="322.46" r="8.5" />
        <circle id="Ellipse_26-69" data-name="Ellipse 26-69" className="about-cls-2" cx="139.31" cy="348.62" r="8.5" />
        <circle id="Ellipse_26-70" data-name="Ellipse 26-70" className="about-cls-2" cx="165.48" cy="374.78" r="8.5" />
        <circle id="Ellipse_26-71" data-name="Ellipse 26-71" className="about-cls-2" cx="191.64" cy="400.94" r="8.5" />
        <circle id="Ellipse_26-72" data-name="Ellipse 26-72" className="about-cls-2" cx="217.8" cy="427.11" r="8.5" />
      </animated.g>
      <animated.g>
        <path
          className="about-cls-3"
          d="M422.81,409.4,431,284.91,319.7,339.17,422.81,409.4m4.42,9.06-117.47-80,126.76-61.83Z"
          transform="translate(-0.54 -1.36)"
        />
        <path
          className="about-cls-2"
          d="M442.32,386.26l8.15-124.49L339.2,316l103.12,70.23m4.41,9.06-117.47-80L456,253.49Z"
          transform="translate(-0.54 -1.36)"
        />
      </animated.g>
      <animated.g>
        <rect
          className="about-cls-4"
          x="532.17"
          y="106.51"
          width="72.82"
          height="72.82"
          transform="translate(128.41 529.42) rotate(-55.53)"
        />
        <rect
          className="about-cls-5"
          x="510.13"
          y="108.23"
          width="72.82"
          height="72.82"
          transform="translate(117.42 511.99) rotate(-55.53)"
        />
      </animated.g>
      <animated.g>
        <circle className="about-cls-6" cx="624.58" cy="290.2" r="45.1" />
        <circle className="about-cls-7" cx="611.8" cy="277.45" r="45.1" />
      </animated.g>
      <animated.g>
        <path
          className="about-cls-8"
          d="M753,206.26l-15.28,12-13.3-17,15.28-11.95-12.4-15.86,17.45-13.64,12.4,15.86,15.28-12,13.31,17-15.29,12,12.4,15.86-17.45,13.64Z"
          transform="translate(-0.54 -1.36)"
        />
        <path
          className="about-cls-9"
          d="M736.72,205l-15.28,11.95-13.3-17L723.42,188,711,172.11l17.45-13.64,12.4,15.86,15.28-12,13.31,17-15.29,11.95,12.4,15.86-17.45,13.64Z"
          transform="translate(-0.54 -1.36)"
        />
      </animated.g>
      <animated.g>
        <path
          className="about-cls-3"
          d="M369.42,137.58a56.33,56.33,0,0,0,72.33-23.67L339.41,67.64a56.31,56.31,0,0,0,30,69.94m-2.06,4.56a61.32,61.32,0,0,1-30.64-81.21L448.56,111.5a61.34,61.34,0,0,1-81.2,30.64Z"
          transform="translate(-0.54 -1.36)"
        />
        <path
          className="about-cls-2"
          d="M363.74,112.74a56.3,56.3,0,0,0,72.33-23.67L333.74,42.8A55.8,55.8,0,0,0,330.59,60a56.6,56.6,0,0,0,15,39.7,55.88,55.88,0,0,0,18.17,13.07m-2.06,4.56A61.35,61.35,0,0,1,331,36.09L442.89,86.66a61.31,61.31,0,0,1-81.21,30.64Z"
          transform="translate(-0.54 -1.36)"
        />
      </animated.g>
    </svg>
  );
};

export const HeroShapes = React.forwardRef((props: any, ref: any) => {
  const { parallax } = props;

  const trans1 = (x: number, y: number) => `translate3d(${x / 6}px,${y / 6}px,0)`;
  const trans2 = (x: number, y: number) => `translate3d(${x / 8}px,${y / 8}px,0)`;
  const trans3 = (x: number, y: number) => `translate3d(${x / 10}px,${y / 10}px,0)`;
  const trans4 = (x: number, y: number) => `translate3d(${x / 12}px,${y / 12}px,0)`;
  const trans5 = (x: number, y: number) => `translate3d(${x / 14}px,${y / 14}px,0) translateX(-50%)`;

  return (
    <div ref={ref}>
      <animated.div
        className="hero-image-container"
        style={{
          transform: parallax.xy.interpolate(trans5),
        }}
      >
        <Img className="hero-image" placeholder={HeroPlaceholder} src={JRKHero} alt="Garrett Hampton Hero" lazy={true} />
      </animated.div>

      <div className="hero-shapes-wrapper">
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 930.28 725.01">
          <animated.g id="Polygon_4" data-name="Polygon 4" style={{ transform: parallax.xy.interpolate(trans3) }}>
            <path
              className="hero-cls-2"
              d="M763.12,291.61l26.45,121.94,92-82.82L763.12,291.61m-6.74-7.5,135,44.57L786.51,423Z"
              transform="translate(-0.61 -69)"
            />
            <path
              className="hero-cls-3"
              d="M739.5,270.11,766,392.05l92-82.82L739.5,270.11m-6.75-7.49,135,44.57L762.89,401.54Z"
              transform="translate(-0.61 -69)"
            />
          </animated.g>
          <animated.g id="Group_186" data-name="Group 186" style={{ transform: parallax.xy.interpolate(trans4) }}>
            <g id="Ellipse_6" data-name="Ellipse 6">
              <circle className="hero-cls-1" cx="498.23" cy="419.19" r="242.03" />
              <circle className="hero-cls-4" cx="498.23" cy="419.19" r="238.03" />
            </g>
            <g id="Ellipse_1" data-name="Ellipse 1">
              <circle className="hero-cls-1" cx="485.98" cy="383.8" r="242.03" />
              <circle className="hero-cls-5" cx="485.98" cy="383.8" r="238.03" />
            </g>
          </animated.g>
          <animated.g id="Group_191" data-name="Group 191" style={{ transform: parallax.xy.interpolate(trans2) }}>
            <rect
              className="hero-cls-6"
              x="729.02"
              y="126.73"
              width="86.01"
              height="86.01"
              transform="translate(105.42 526.52) rotate(-44.99)"
            />
            <rect
              className="hero-cls-7"
              x="700.87"
              y="127.47"
              width="86.01"
              height="86.01"
              transform="translate(96.65 506.83) rotate(-44.99)"
            />
          </animated.g>
          <animated.g id="Group_194" data-name="Group 194" style={{ transform: parallax.xy.interpolate(trans1) }}>
            <g id="Ellipse_5" data-name="Ellipse 5">
              <circle className="hero-cls-1" cx="888.77" cy="57.44" r="41.5" />
              <circle className="hero-cls-8" cx="888.77" cy="57.44" r="39" />
            </g>
            <g id="Ellipse_3" data-name="Ellipse 3">
              <circle className="hero-cls-1" cx="879.58" cy="41.52" r="41.5" />
              <circle className="hero-cls-9" cx="879.58" cy="41.52" r="39" />
            </g>
          </animated.g>
          <animated.g id="Group_190" data-name="Group 190" style={{ transform: parallax.xy.interpolate(trans3) }}>
            <path
              className="hero-cls-11"
              d="M599.87,181.84l-7-18.11L613.08,156,620,174.1l18.79-7.21,7.94,20.67L628,194.77l6.95,18.11-20.16,7.74-7-18.11L589,209.72l-7.93-20.67Z"
              transform="translate(-0.61 -69)"
            />
            <path
              className="hero-cls-12"
              d="M605.84,166.69l-7-18.11L619,140.84,626,159l18.79-7.21,7.93,20.67-18.79,7.22,7,18.11-20.16,7.73-7-18.1L595,194.58,587,173.91Z"
              transform="translate(-0.61 -69)"
            />
          </animated.g>
          <animated.g id="Group_189" data-name="Group 189" style={{ transform: parallax.xy.interpolate(trans2) }}>
            <path
              className="hero-cls-2"
              d="M897.52,242.71a40.69,40.69,0,0,0-14.31-52.77l-36.79,72.2a40.56,40.56,0,0,0,27.41.8,40.49,40.49,0,0,0,23.69-20.23m3.56,1.82A44.67,44.67,0,0,1,841,264.06l40.6-79.67a44.68,44.68,0,0,1,19.53,60.14Z"
              transform="translate(-0.61 -69)"
            />
            <path
              className="hero-cls-3"
              d="M880.61,241.83a40.66,40.66,0,0,0-14.32-52.76l-36.78,72.2a40.19,40.19,0,0,0,11.91,2.7,40.66,40.66,0,0,0,39.19-22.14m3.56,1.82A44.67,44.67,0,0,1,824,263.19l40.59-79.67a44.67,44.67,0,0,1,19.54,60.13Z"
              transform="translate(-0.61 -69)"
            />
          </animated.g>
          <animated.g id="Polygon_4-2" data-name="Polygon 4-2" style={{ transform: parallax.xy.interpolate(trans3) }}>
            <path
              className="hero-cls-2"
              d="M205.73,569.64,175,448.72,86,534.7l119.77,34.94m7,7.25L76.29,537.09l101.46-98Z"
              transform="translate(-0.61 -69)"
            />
            <path
              className="hero-cls-3"
              d="M224.37,590.14,193.66,469.22l-89.06,86,119.77,34.94m7,7.25L94.92,557.59l101.47-98Z"
              transform="translate(-0.61 -69)"
            />
          </animated.g>
          <animated.g id="Group_193" data-name="Group 193" style={{ transform: parallax.xy.interpolate(trans2) }}>
            <rect
              className="hero-cls-13"
              x="138.79"
              y="689.48"
              width="86"
              height="86"
              transform="translate(-323.16 102.04) rotate(-27.99)"
            />
            <rect
              className="hero-cls-14"
              x="146.32"
              y="662.34"
              width="86"
              height="86"
              transform="translate(-309.54 102.39) rotate(-27.99)"
            />
          </animated.g>
          <animated.g id="Group_196" data-name="Group 196" style={{ transform: parallax.xy.interpolate(trans1) }}>
            <circle className="hero-cls-15" cx="41.53" cy="660.06" r="39.02" />
            <circle className="hero-cls-16" cx="48.12" cy="677.24" r="39.02" />
          </animated.g>
          <animated.g id="Group_195" data-name="Group 195" style={{ transform: parallax.xy.interpolate(trans2) }}>
            <path
              className="hero-cls-11"
              d="M64.68,640.15l-8.2-17.58,19.57-9.13L84.25,631l18.24-8.51,9.36,20.07-18.24,8.51,8.19,17.58L82.23,677.8,74,660.21l-18.25,8.51-9.36-20.07Z"
              transform="translate(-0.61 -69)"
            />
            <path
              className="hero-cls-12"
              d="M69.58,624.62,61.38,607,81,597.92l8.2,17.58L107.39,607l9.36,20.07-18.25,8.5,8.2,17.59-19.57,9.12-8.2-17.58L60.69,653.2l-9.36-20.07Z"
              transform="translate(-0.61 -69)"
            />
          </animated.g>
          <animated.g id="Group_188" data-name="Group 188" style={{ transform: parallax.xy.interpolate(trans3) }}>
            <path
              className="hero-cls-2"
              d="M319,712.22a40.71,40.71,0,0,0-33.53,43.19l79.81-14.07A40.61,40.61,0,0,0,349.42,719,40.57,40.57,0,0,0,319,712.22m-.69-3.94a44.68,44.68,0,0,1,51.79,36.28L282,760.07a44.67,44.67,0,0,1,36.27-51.79Z"
              transform="translate(-0.61 -69)"
            />
            <path
              className="hero-cls-3"
              d="M328.48,726.26a40.73,40.73,0,0,0-32.62,31,39.94,39.94,0,0,0-.91,12.18l79.81-14.06a40.27,40.27,0,0,0-5-11.15,40.82,40.82,0,0,0-25.1-17.57,40.33,40.33,0,0,0-16.16-.4m-.69-3.94a44.67,44.67,0,0,1,51.79,36.27l-88.06,15.52a44.67,44.67,0,0,1,36.27-51.79Z"
              transform="translate(-0.61 -69)"
            />
          </animated.g>
        </svg>
      </div>
    </div>
  );
});

export const BackArrow = () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1248.65 965.3">
      <polygon points="482.65 965.3 636.8 811.15 417.3 591.65 1248.65 591.65 1248.65 373.65 417.3 373.65 636.8 154.15 482.65 0 0 482.65 482.65 965.3" />
    </svg>
  );
};

export const Close = () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 988 988">
      <polygon
        points="948,193.2 988,153.2 948,113.2 834.8,0 794.8,40 494,340.8 193.2,40 153.2,0 113.2,40 40,113.2 0,153.2 340.8,494 
	40,794.8 0,834.8 113.2,948 153.2,988 193.2,948 494,647.2 794.8,948 834.8,988 874.8,948 948,874.8 988,834.8 647.2,494 "
      />
    </svg>
  );
};

export const Devto = () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2321 2321">
      <path
        d="M622.32,913.32Q592.17,890.79,562,890.79H471.61V1432H562q30.16,0,60.36-22.54t30.15-67.66V981q-.07-45.07-30.2-67.67ZM2093.56,0H227.44C102.06,0,.31,101.49,0,226.92V2094.08C.31,2219.51,102.06,2321,227.44,2321H2093.56c125.43,0,227.13-101.49,227.44-226.92V226.92C2320.69,101.49,2218.94,0,2093.56,0ZM798.88,1342.81c0,97.45-60.15,245.11-250.54,244.8H308V730.39H553.41c183.61,0,245.37,147.45,245.42,245Zm521.6-459.33h-276v199h168.74v153.2H1044.45v199h276.09v153.2H998.39a104.68,104.68,0,0,1-107.34-102V837.74A104.75,104.75,0,0,1,993.16,730.55h327.38Zm536.94,597.3c-68.38,159.31-190.91,127.6-245.78,0L1412,730.6h168.74l153.92,589.16,153.2-589.16h168.79Z"
        transform="translate(0)"
      />
    </svg>
  );
};

export const DownArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54">
      <clipPath id="circleClip">
        <polygon
          className="arrow-1"
          points="27 41.17 27 41.17 27 41.17 40.68 27.5 37.14 23.96 29.5 31.6 29.5 12.83 24.5 12.83 24.5 31.6 16.86 23.96 13.32 27.5 27 41.17"
        />
        <polygon
          className="arrow-2"
          points="27 41.17 27 41.17 27 41.17 40.68 27.5 37.14 23.96 29.5 31.6 29.5 12.83 24.5 12.83 24.5 31.6 16.86 23.96 13.32 27.5 27 41.17"
        />
      </clipPath>

      <circle clipPath="url(#circleClip)" cx="27" cy="27" r="27" />

      <g id="Ellipse_19" data-name="Ellipse 9">
        <circle style={{ fill: "none" }} cx="27" cy="27" r="27" />
        <path
          d="M27.65,54.87a27,27,0,1,1,27-27A27,27,0,0,1,27.65,54.87Zm0-50a23,23,0,1,0,23,23A23,23,0,0,0,27.65,4.87Z"
          transform="translate(-0.65 -0.87)"
        />
      </g>
    </svg>
  );
};

export const DownloadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="#fff"
        d="M15.003 3h2.997v5h-2.997v-5zm8.997 1v20h-24v-24h20l4 4zm-19 5h14v-7h-14v7zm16 4h-18v9h18v-9z"
      />
    </svg>
  );
};

export const EmailIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="#fff"
        d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z"
      />
    </svg>
  );
};

export const ExpandWork = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.04 17.99">
      <polygon points="18.04 0 18.04 7 16.04 7 16.04 3.4 9.73 9.71 8.33 8.31 11.84 4.8 14.64 2 11.04 2 11.04 0 18.04 0" />
      <polygon points="3.45 15.99 7.04 15.99 7.04 17.99 0.05 17.99 0.05 11 2.05 11 2.05 14.6 8.36 8.29 9.76 9.69 3.45 15.99" />
      <path d="M12,3V5H5v7H3V5A2,2,0,0,1,5,3Z" transform="translate(-3 -3)" />
      <path d="M21,12v7a2,2,0,0,1-2,2H12V19h7V12Z" transform="translate(-3 -3)" />
    </svg>
  );
};

export const ExternalLinkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <polyline points="11 0 11 2 14.6 2 4.8 11.8 6.2 13.2 16 3.4 16 7 18 7 18 0" />
      <path d="M19,19H5V5h7V3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V12H19Z" transform="translate(-3 -3)" />
    </svg>
  );
};

export const Facebook = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"></path>
    </svg>
  );
};

export const Instagram = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"></path>
    </svg>
  );
};

export const Reddit = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M14.558 15.827c.097.096.097.253 0 .349-.531.529-1.365.786-2.549.786l-.009-.002-.009.002c-1.185 0-2.018-.257-2.549-.786-.097-.096-.097-.253 0-.349.096-.096.254-.096.351 0 .433.431 1.152.641 2.199.641l.009.002.009-.002c1.046 0 1.765-.21 2.199-.641.095-.097.252-.097.349 0zm-.126-3.814c-.581 0-1.054.471-1.054 1.05 0 .579.473 1.049 1.054 1.049.581 0 1.054-.471 1.054-1.049 0-.579-.473-1.05-1.054-1.05zm-3.806 1.05c0-.579-.473-1.05-1.054-1.05-.581 0-1.055.471-1.055 1.05 0 .579.473 1.049 1.055 1.049.581.001 1.054-.47 1.054-1.049zm13.374-8.063v14c0 2.761-2.238 5-5 5h-14c-2.761 0-5-2.239-5-5v-14c0-2.761 2.239-5 5-5h14c2.762 0 5 2.239 5 5zm-4 6.853c0-.972-.795-1.764-1.772-1.764-.477 0-.908.191-1.227.497-1.207-.794-2.84-1.299-4.647-1.364l.989-3.113 2.677.628-.004.039c0 .795.65 1.442 1.449 1.442.798 0 1.448-.647 1.448-1.442 0-.795-.65-1.442-1.448-1.442-.613 0-1.136.383-1.347.919l-2.886-.676c-.126-.031-.254.042-.293.166l-1.103 3.471c-1.892.023-3.606.532-4.867 1.35-.316-.292-.736-.474-1.2-.474-.975-.001-1.769.79-1.769 1.763 0 .647.355 1.207.878 1.514-.034.188-.057.378-.057.572 0 2.607 3.206 4.728 7.146 4.728 3.941 0 7.146-2.121 7.146-4.728 0-.183-.019-.362-.05-.54.555-.299.937-.876.937-1.546z"></path>
    </svg>
  );
};

export const Twitter = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
    </svg>
  );
};

export const Github = () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 23.41">
      <path
        d="M11.51,0a12,12,0,0,0-3.3,23.38c.6.11.79-.26.79-.58V20.58c-3.34.72-4-1.42-4-1.42A3.19,3.19,0,0,0,3.63,17.4c-1.08-.74.09-.72.09-.72a2.52,2.52,0,0,1,1.84,1.23,2.54,2.54,0,0,0,3.49,1,2.53,2.53,0,0,1,.76-1.6C7.15,17,4.34,16,4.34,11.37A4.63,4.63,0,0,1,5.58,8.15,4.28,4.28,0,0,1,5.7,5s1-.32,3.3,1.23a11.49,11.49,0,0,1,3-.41,11.63,11.63,0,0,1,3,.41C17.3,4.66,18.3,5,18.3,5a4.23,4.23,0,0,1,.12,3.17,4.63,4.63,0,0,1,1.24,3.22c0,4.61-2.81,5.63-5.48,5.93A2.86,2.86,0,0,1,15,19.52v3.29c0,.32.19.69.8.58A12,12,0,0,0,11.51,0Z"
        transform="translate(0 0)"
      />
    </svg>
  );
};

export const GSHLogo = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 159 74">
      <g>
        <path
          fill="#B2C1B6"
          d="M11.46,37.52c0-14.47,10.23-25.06,24.99-25.06c7.99,0,13.75,2.16,17.79,6.77L47.39,26 c-2.45-2.52-5.26-4.46-10.94-4.46c-8.86,0-14.62,6.84-14.62,15.99c0,9.14,5.9,16.13,16.2,16.13c3.17,0,7.06-0.58,9.58-1.8v-7.2 H35.79v-8.86h21.03v21.17c-2.81,2.3-9.86,5.69-18.79,5.69C22.04,62.65,11.46,51.99,11.46,37.52z"
        ></path>
        <path
          fill="#B2C1B6"
          d="M62.07,52.35l8.21-4.75c2.16,3.53,4.97,6.12,9.94,6.12c4.18,0,6.84-2.09,6.84-4.97c0-3.46-2.74-4.68-7.34-6.7 l-2.52-1.08c-7.27-3.1-12.1-6.98-12.1-15.19c0-7.56,5.76-13.32,14.76-13.32c6.41,0,11.02,2.23,14.33,8.06l-7.85,5.04 c-1.73-3.1-3.6-4.32-6.48-4.32c-2.95,0-4.82,1.87-4.82,4.32c0,3.02,1.87,4.25,6.19,6.12l2.52,1.08 c8.57,3.67,13.39,7.42,13.39,15.84c0,9.07-7.13,14.04-16.71,14.04C71.07,62.65,65.02,58.18,62.07,52.35z"
        ></path>
        <path
          fill="#B2C1B6"
          d="M104.98,13.04h10.08v25.06h20.95V13.04h10.08V62h-10.08V47.09h-20.95V62h-10.08V13.04z"
        ></path>
      </g>
    </svg>
  );
};

export const Hamburger = () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1043.68 999">
      <polygon points="1043.7,0 0,0 0,191.5 1043.7,191.5 1043.7,0 	" />
      <polygon points="0,402.8 0,594.2 1043.7,594.2 1043.7,402.8 1043.7,402.8 	" />
      <polygon points="0,807.5 0,999 1043.7,999 1043.7,807.5 1043.7,807.5 	" />
    </svg>
  );
};

export const GSHLogoStroke = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 159 74">
      <g>
        <path
          stroke="#B2C1B6"
          fill="none"
          d="M11.46,37.52c0-14.47,10.23-25.06,24.99-25.06c7.99,0,13.75,2.16,17.79,6.77L47.39,26 c-2.45-2.52-5.26-4.46-10.94-4.46c-8.86,0-14.62,6.84-14.62,15.99c0,9.14,5.9,16.13,16.2,16.13c3.17,0,7.06-0.58,9.58-1.8v-7.2 H35.79v-8.86h21.03v21.17c-2.81,2.3-9.86,5.69-18.79,5.69C22.04,62.65,11.46,51.99,11.46,37.52z"
        ></path>
        <path
          stroke="#B2C1B6"
          fill="none"
          d="M62.07,52.35l8.21-4.75c2.16,3.53,4.97,6.12,9.94,6.12c4.18,0,6.84-2.09,6.84-4.97c0-3.46-2.74-4.68-7.34-6.7 l-2.52-1.08c-7.27-3.1-12.1-6.98-12.1-15.19c0-7.56,5.76-13.32,14.76-13.32c6.41,0,11.02,2.23,14.33,8.06l-7.85,5.04 c-1.73-3.1-3.6-4.32-6.48-4.32c-2.95,0-4.82,1.87-4.82,4.32c0,3.02,1.87,4.25,6.19,6.12l2.52,1.08 c8.57,3.67,13.39,7.42,13.39,15.84c0,9.07-7.13,14.04-16.71,14.04C71.07,62.65,65.02,58.18,62.07,52.35z"
        ></path>
        <path
          stroke="#B2C1B6"
          fill="none"
          d="M104.98,13.04h10.08v25.06h20.95V13.04h10.08V62h-10.08V47.09h-20.95V62h-10.08V13.04z"
        ></path>
      </g>
    </svg>
  );
};

export const LeftArrow: FC<{ color?: string }> = (props) => {
  const { color } = props;
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.07 37.07"
      style={{ fill: color ? color : "" }}
    >
      <polygon points="22.07 3.54 18.54 0 3.54 15 0 18.54 18.54 37.07 22.07 33.54 7.07 18.54 22.07 3.54" />
    </svg>
  );
};

export const LinkedIn = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
    </svg>
  );
};

export const LinkedInBox = () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M21.45,0H2.55A2.55,2.55,0,0,0,0,2.55v18.9A2.55,2.55,0,0,0,2.55,24h18.9A2.55,2.55,0,0,0,24,21.45V2.55A2.55,2.55,0,0,0,21.45,0ZM8,19H5V8H8ZM6.5,6.73A1.77,1.77,0,1,1,8.25,5,1.75,1.75,0,0,1,6.5,6.73ZM20,19H17V13.4c0-3.37-4-3.12-4,0V19H10V8h3V9.77c1.4-2.59,7-2.78,7,2.47Z" />
    </svg>
  );
};

export const LinkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z" />
    </svg>
  );
};

export const PictureIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M5 8.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5zm9 .5l-2.519 4-2.481-1.96-4 5.96h14l-5-8zm8-4v14h-20v-14h20zm2-2h-24v18h24v-18z" />
    </svg>
  );
};

export const Preview = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z" />
    </svg>
  );
};

export const RightArrow: FC<{ color?: string }> = (props) => {
  const { color } = props;
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.07 37.07"
      style={{ fill: color ? color : "" }}
    >
      <polygon points="15,18.5 0,33.5 3.5,37.1 22.1,18.5 18.5,15 3.5,0 0,3.5 " />
    </svg>
  );
};

export const ScrollToTop = () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67 67">
      <rect className="cls-1" width="67" height="67" rx="7.5" />
      <polygon
        className="cls-2"
        points="33.5 14.84 33.5 14.84 33.5 14.84 15.49 32.85 20.14 37.5 30.21 27.44 30.21 52.16 36.79 52.16 36.79 27.44 46.85 37.5 51.51 32.85 33.5 14.84"
      />
    </svg>
  );
};
