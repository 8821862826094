import React, { FC } from "react";
import { AboutShapes } from "../assets/svg";
import { Content } from "../components/Content";
import WorkCard from "../components/WorkCard";
import WorkWrapper from "../components/WorkWrapper";
import { ModalDetails } from "../util";
import { images, Image } from "../util/image";

interface Props {
  isMobile: boolean;
  viewport: number;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setModalInfo: React.Dispatch<React.SetStateAction<ModalDetails | undefined>>;
  setIsImageModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setImageModalList: React.Dispatch<React.SetStateAction<Image[]>>;
  workRef: React.MutableRefObject<null>;
}

export const Work: FC<Props> = ({
  isMobile,
  viewport,
  setIsModalOpen,
  setModalInfo,
  setIsImageModalOpen,
  setImageModalList,
  workRef,
}) => {
  return (
    <section id="work" ref={workRef}>
      <Content>
        {isMobile ? <></> : <AboutShapes />}
        <h1>Work</h1>
        <WorkWrapper>
          <WorkCard
            description={
              "MainStem™ is a B2B Purchasing Platform™ created for the cannabis industry.  It is the largest purchasing platform in the industry and was awarded Series B funding from Merida."
            }
            primaryColor={"#440099"}
            title={"MainStem"}
            tags={["CTO"]}
            viewport={viewport}
            github={"https://github.com/GarrettSHampton"}
            live={"https://www.mainstem.io"}
            preview={"https://vimeo.com/412508762"}
            thumbnail={images.mainstem[0]}
            images={images.mainstem}
            setIsModalOpen={setIsModalOpen}
            setIsImageModalOpen={setIsImageModalOpen}
            setImageModalList={setImageModalList}
            setModalInfo={setModalInfo}
          />
          <WorkCard
            description={
              "Finify™ serves to be an automated personal finance management platform for the average person.  It helps users track their income, bills, savings and debts and sets goals for"
            }
            title={"Finify™"}
            primaryColor={"#5c4cbc"}
            tags={["Founder", "CTO"]}
            github={"https://github.com/GarrettSHampton"}
            live={"https://www.finify.io"}
            viewport={viewport}
            thumbnail={images.finify[0]}
            images={images.finify}
            setIsModalOpen={setIsModalOpen}
            setIsImageModalOpen={setIsImageModalOpen}
            setImageModalList={setImageModalList}
            setModalInfo={setModalInfo}
          />
          <WorkCard
            description={
              "MassPile™ is a community funded algo trading platform based off of block-chain.  Its community members deposit money into a central fund and then community created bots make algo..."
            }
            title={"MassPile™"}
            primaryColor={"#702E90"}
            tags={["Founder", "CTO"]}
            live={"https://www.masspile.com"}
            viewport={viewport}
            thumbnail={images.masspile[0]}
            images={images.masspile}
            setIsModalOpen={setIsModalOpen}
            setIsImageModalOpen={setIsImageModalOpen}
            setImageModalList={setImageModalList}
            setModalInfo={setModalInfo}
          />
          <WorkCard
            description={
              "WeStride is empowerment as a platform for people to share their stories and experiences with others."
            }
            title={"WeStride"}
            primaryColor={"#C1272D"}
            tags={["Founder", "CTO"]}
            live={"https://www.westride.io"}
            viewport={viewport}
            thumbnail={images.westride[0]}
            images={images.westride}
            setIsModalOpen={setIsModalOpen}
            setIsImageModalOpen={setIsImageModalOpen}
            setImageModalList={setImageModalList}
            setModalInfo={setModalInfo}
          />
          <WorkCard
            description={
              "Original Pioneers is a modern digital marketing, strategy, and technology company.  It is a company that has been in the business of creating digital marketing strategies and technology for over 20 years."
            }
            title={"Original Pioneers"}
            primaryColor={"#4f46e5"}
            tags={["Founder", "CTO"]}
            live={"https://www.originalpioneers.com"}
            viewport={viewport}
            thumbnail={images.originalPioneers[0]}
            images={images.originalPioneers}
            setIsModalOpen={setIsModalOpen}
            setIsImageModalOpen={setIsImageModalOpen}
            setImageModalList={setImageModalList}
            setModalInfo={setModalInfo}
          />
        </WorkWrapper>
      </Content>
    </section>
  );
};
