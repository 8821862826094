// MainStem
import MainStem1 from "../assets/images/mainstem/Screenshot_1.jpg";
import MainStem2 from "../assets/images/mainstem/Screenshot_2.jpg";
import MainStem3 from "../assets/images/mainstem/Screenshot_3.jpg";
import MainStem4 from "../assets/images/mainstem/Screenshot_4.jpg";
import MainStem5 from "../assets/images/mainstem/Screenshot_5.jpg";
// Finify
import Finify1 from "../assets/images/finify/Screenshot_1.jpg";
import Finify2 from "../assets/images/finify/Screenshot_2.jpg";
import Finify3 from "../assets/images/finify/Screenshot_1.jpg";
import Finify4 from "../assets/images/finify/Screenshot_4.jpg";
import Finify5 from "../assets/images/finify/Screenshot_5.jpg";
// OriginalPioneers
import OriginalPioneers1 from "../assets/images/original-pioneers/Screenshot_1.jpg";
import OriginalPioneers2 from "../assets/images/original-pioneers/Screenshot_2.jpg";
import OriginalPioneers3 from "../assets/images/original-pioneers/Screenshot_1.jpg";
import OriginalPioneers4 from "../assets/images/original-pioneers/Screenshot_3.jpg";
import OriginalPioneers5 from "../assets/images/original-pioneers/Screenshot_4.jpg";
// WeStride
import WeStride1 from "../assets/images/westride/Screenshot_1.jpg";
import WeStride2 from "../assets/images/westride/Screenshot_2.jpg";
import WeStride3 from "../assets/images/westride/Screenshot_1.jpg";
import WeStride4 from "../assets/images/westride/Screenshot_3.jpg";
import WeStride5 from "../assets/images/westride/Screenshot_3.jpg";
// MassPile
import MassPile1 from "../assets/images/masspile/Screenshot_1.jpg";
import MassPile2 from "../assets/images/masspile/Screenshot_2.jpg";
import MassPile3 from "../assets/images/masspile/Screenshot_1.jpg";
import MassPile4 from "../assets/images/masspile/Screenshot_2.jpg";
import MassPile5 from "../assets/images/masspile/Screenshot_2.jpg";

//Articles
import FullStackApplication from "../assets/images/articles/full-stack.jpg";
import Networking from "../assets/images/articles/networking.jpg";
import Appearance from "../assets/images/articles/professional-apperance.jpg";

export interface Image {
  width: number;
  height: number;
  src: string;
  alt: string;
}

export const images: {
  mainstem: Image[];
  finify: Image[];
  westride: Image[];
  originalPioneers: Image[];
  masspile: Image[];
  articles: {
    networking: Image;
    fullstack: Image;
    appearance: Image;
  };
} = {
  mainstem: [
    {
      width: 1920,
      height: 1080,

      src: MainStem1,
      alt: "MainStem Screenshot #1",
    },
    {
      width: 1920,
      height: 1080,

      src: MainStem2,
      alt: "MainStem Screenshot #2",
    },
    {
      width: 1920,
      height: 1080,

      src: MainStem3,
      alt: "MainStem Screenshot #3",
    },
    {
      width: 1920,
      height: 1080,

      src: MainStem4,
      alt: "MainStem Screenshot #4",
    },
    {
      width: 1920,
      height: 1080,

      src: MainStem5,
      alt: "MainStem Screenshot #5",
    },
  ],
  finify: [
    {
      width: 1920,
      height: 1080,

      src: Finify1,
      alt: "Finify Screenshot #1",
    },
    {
      width: 1920,
      height: 1080,

      src: Finify2,
      alt: "Finify Screenshot #2",
    },
    {
      width: 1920,
      height: 1080,

      src: Finify3,
      alt: "Finify Screenshot #3",
    },
    {
      width: 1920,
      height: 1080,

      src: Finify4,
      alt: "Finify Screenshot #4",
    },
    {
      width: 1920,
      height: 1080,

      src: Finify5,
      alt: "Finify Screenshot #5",
    },
  ],
  westride: [
    {
      width: 1920,
      height: 1080,

      src: WeStride1,
      alt: "WeStride Screenshot #1",
    },
    {
      width: 1920,
      height: 1080,

      src: WeStride2,
      alt: "WeStride Screenshot #2",
    },
    {
      width: 1920,
      height: 1080,

      src: WeStride3,
      alt: "WeStride Screenshot #3",
    },
    {
      width: 1920,
      height: 1080,

      src: WeStride4,
      alt: "WeStride Screenshot #4",
    },
    {
      width: 1920,
      height: 1080,

      src: WeStride5,
      alt: "WeStride Screenshot #5",
    },
  ],
  originalPioneers: [
    {
      width: 1920,
      height: 1080,

      src: OriginalPioneers1,
      alt: "OriginalPioneers Screenshot #1",
    },
    {
      width: 1920,
      height: 1080,

      src: OriginalPioneers2,
      alt: "OriginalPioneers Screenshot #2",
    },
    {
      width: 1920,
      height: 1080,

      src: OriginalPioneers3,
      alt: "OriginalPioneers Screenshot #3",
    },
    {
      width: 1920,
      height: 1080,

      src: OriginalPioneers4,
      alt: "OriginalPioneers Screenshot #4",
    },
    {
      width: 1920,
      height: 1080,

      src: OriginalPioneers5,
      alt: "OriginalPioneers Screenshot #5",
    },
  ],
  masspile: [
    {
      width: 1920,
      height: 1080,

      src: MassPile1,
      alt: "MassPile Screenshot #1",
    },
    {
      width: 1920,
      height: 1080,

      src: MassPile2,
      alt: "MassPile Screenshot #2",
    },
    {
      width: 1920,
      height: 1080,

      src: MassPile3,
      alt: "MassPile Screenshot #3",
    },
    {
      width: 1920,
      height: 1080,

      src: MassPile4,
      alt: "MassPile Screenshot #4",
    },
    {
      width: 1920,
      height: 1080,

      src: MassPile5,
      alt: "MassPile Screenshot #5",
    },
  ], 
  articles: {
    networking: {
      width: 2559,
      height: 1326,
      src: Networking,
      alt: "Networking article by Garrett Hampton",
    },
    fullstack: {
      width: 2559,
      height: 1326,
      src: FullStackApplication,
      alt: "Full-stack web app article by Garrett Hampton",
    },
    appearance: {
      width: 2559,
      height: 1326,
      src: Appearance,
      alt: "Professional appearance by Garrett Hampton",
    },
  },
};
