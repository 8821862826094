import React from "react";
import { AboutShapes } from "../assets/svg";
import { Content } from "../components/Content";
import { Element } from "react-scroll";
import { ExternalLink } from "../components/ExternalLink";

interface Props {
  isMobile: boolean;
  aboutRef: React.MutableRefObject<null>;
}

export function About(props: Props) {
  const { isMobile, aboutRef } = props;

  return (
    <Element name="home">
      <section id="about" ref={aboutRef}>
        <Content>
          {isMobile ? null : <AboutShapes />}
          <h1>About</h1>
          <p>
            I am a seasoned CTO &amp; software engineer who is currently working at{" "}
            <ExternalLink text={"MainStem"} link={"https://www.mainstem.io"} /> and the founder of{" "}
            <ExternalLink text={"Finify"} link={"https://www.finify.io"} /> and
            <ExternalLink text={"MassPile"} link={"https://www.masspile.com"} />. In my free time, I enjoy hiking,
            fishing, contributing to open-source, and working on side projects. Some technologies I enjoy are
            TypeScript, Go, C#, React.js, Python, and Node.js. I have been writing code professionally for over 15 years
            but have been a hobbyist programmer since I was a kid.
          </p>
          <br />
          <p>
            I have several successful acquisitions and exits under my belt and look forward to adding more in the
            future. My experience is primarily in banking, FinTech, real estate, supply chain and medical radiology
            software.
          </p>
        </Content>
      </section>
    </Element>
  );
}
